import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import "./index.css";
import Front from "./Front";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    beforeSend(event, hint) {
        const error = hint.originalException;
        // Vérifie si c'est un APIError 402 avec le message spécifique
        if (
            error &&
            error.status === 402 &&
            error.message.includes("doit être payée pour réserver")
        ) {
            return null; // Ignore uniquement cette erreur précise
        }
        if (
            error &&
            error.status === 403 &&
            error.message.includes("Les réservations ne sont pas ouvertes")
        ) {
            return null; // Ignore uniquement cette erreur précise
        }
        return event;
    },
});

ReactDOM.render(<Front />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
